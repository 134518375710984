import React, {Suspense} from 'react';
import {
    Routes ,
    Route,
  } from "react-router-dom";

import Navigation from '../components/header/Navigation';
import Footer from '../components/footer/Footer'
import history from "../history";
import { HelmetProvider} from "react-helmet-async";
import AIRouter from "./AIRouter";
import ScrollToTop from "./ScrollToTop";

//PAGES
const Home = React.lazy(() => import('../pages/Home'));
const AIWhyAlumo = React.lazy(() => import('../pages/WhyAlumo'));
// const AIRent = React.lazy(() => import('../pages/Rent'));
// const AIOWn = React.lazy(() => import('../pages/Own'));
const AICustomerExperience = React.lazy(() => import('../pages/customer-experience/page'));
const AboutUs = React.lazy(() => import('../pages/About'));
const AISingleCustomerExperience = React.lazy(() => import('../pages/customer-experience/single/page'));
const AIProducts = React.lazy(() => import('../pages/products/page'));
const AISingleProduct = React.lazy(() => import('../pages/products/single/page'));
const AIPromotions = React.lazy(() => import('../pages/promotions/page'));
const AIPromotionsSingle = React.lazy(() => import('../pages/promotions/single/page'));
// const AIHowYouPay = React.lazy(() => import('../pages/HowYouPay'));
const AIThankYou = React.lazy(() => import('../pages/ThankYou'));
const StartSaving = React.lazy(() => import('../pages/start-saving/page'));
const StartSavingForm = React.lazy(() => import('../pages/start-saving/StartSavingForm'));
const BookConsultation = React.lazy(() => import('../pages/BookConsultation'));
const StartSavingThankYou = React.lazy(() => import('../pages/start-saving/StartSavingThankYou'));
const AIFaq = React.lazy(() => import('../pages/FAQ'));
const AILegal = React.lazy(() => import('../pages/Legal'));
const AIContact = React.lazy(() => import('../pages/Contact'));
const ExpandAsYouGo = React.lazy(() => import('../pages/ExpandAsYouGo'));
const AISearchResults = React.lazy(() => import('../pages/SearchResults'));
const AI404 = React.lazy(() => import('../pages/404'));
const Residential = React.lazy(() => import('../pages/residential/page'));
const BackupSolutions = React.lazy(() => import('../pages/BackupSolutions'));
const SolarBatterySolutions = React.lazy(() => import('../pages/SolarAndBatterySolutions'));
// const HotWaterSolarSolutions = React.lazy(() => import('../pages/HotWaterSolarSolutions'));
const BrandsAlpha = React.lazy(() => import('../pages/BrandsAlpha'));
const BrandsSunsynk = React.lazy(() => import('../pages/BrandsSunsynk'));
const AIWhatWeDo = React.lazy(() => import('../pages/WhatWeDo'));
const Articles = React.lazy(() => import('../pages/articles/page'));
const SingleArticle = React.lazy(() => import('../pages/articles/single/page'));
const AIPrivacyPolicy = React.lazy(() => import('../pages/PrivacyPolicy'));
const InstantQuote = React.lazy(() => import('../pages/InstantQuote'));
const SizingTool = React.lazy(() => import('../pages/SizingTool'));
const AITermsAndConditions = React.lazy(() => import('../pages/TermsAndConditions'));
const QuicklySignThankYou = React.lazy(() => import('../pages/QuicklySignThankYou'));
const Careers = React.lazy(() => import('../pages/Careers'));
const SolarRentals = React.lazy(() => import('../pages/guides/solar-rentals/page'));
const SolarSystemsSouthAfrica = React.lazy(() => import('../pages/guides/solar-systems-south-africa/page'));
const RentalSolar = React.lazy(() => import('../pages/guides/solar-rentals/rental-solar-reduce-electricity-costs/page'));
const Disclaimer = React.lazy(() => import('../pages/Disclaimer'));
const OffGridSolarSystem = React.lazy(() => import('../pages/guides/off-grid-solar-system/page'));
const GoSolar = React.lazy(() => import('../pages/guides/page'));
const SolarSystemWithBattery = React.lazy(() => import('../pages/guides/solar-backup/solar-system-with-battery/page'));
const SolarBackup = React.lazy(() => import('../pages/guides/solar-backup/page'));
const SolarSolutionsForHomeWhyGoSolar = React.lazy(() => import('../pages/guides/solar-solutions-for-home/page'));
const SolarPowerSystemForHome = React.lazy(() => import('../pages/SolarPowerSystemForHome'));
const SolarPanelBatterGetSwitched = React.lazy(() => import('../pages/guides/solar-backup/solar-panel-battery-details/page'));
const SolarBatteryBackupSystems = React.lazy(() => import('../pages/guides/solar-backup/solar-battery-backup-systems/page'));
const SolarInstallation = React.lazy(() => import('../pages/guides/solar-systems-south-africa/solar-installation/page'));
const SolarPanelPrices = React.lazy(() => import('../pages/SolarPanelPrices'));
const RentToOwnSystem = React.lazy(() => import('../pages/guides/solar-rentals/rent-to-own-solar-system/page'));
const Commercial = React.lazy(() => import('../pages/commercial/page'));
const MaintenanceAndRepairs = React.lazy(() => import('../pages/MaintenanceAndRepairs'));
const Subscription = React.lazy(() => import('../pages/Subscription'));
const Login = React.lazy(() => import('../pages/Login'));
const Feedback = React.lazy(() => import('../pages/Feedback'));

function RouteIndex() {
      return (
          <Suspense fallback={<div className={'ai-suspense-fallback'}></div>}>
              <AIRouter history={history}>
                  <div className={'ai-page-wrapper'}>
                      <ScrollToTop/>
                          <HelmetProvider>
                              <div className={'page-content'}>
                                  <Navigation/>
                                    <Routes>
                                    <Route  path='/'  element={<Home/>}></Route>
                                    <Route  path='/why-alumo' element={<AIWhyAlumo/>}></Route>
                                    {/*<Route  path='/rent-to-own-solar-system' element={<AIRent/>}></Route>*/}
                                    {/*<Route  path='/solar-panel-for-sale' element={<AIOWn/>}></Route>*/}
                                    <Route  path='/customer-experiences' element={<AICustomerExperience />}></Route>
                                    <Route  path='/customer-experiences/:slug' element={<AISingleCustomerExperience />}></Route>
                                    <Route  path='/home-solar-solutions' element={<AIProducts />}></Route>
                                    <Route  path='/home-solar-solutions/:slug' element={<AISingleProduct />}></Route>
                                    <Route  path='/about-us' element={<AboutUs/>}></Route>
                                    <Route  path='/promotions' element={<AIPromotions/>}></Route>
                                    <Route  path='/promotions/:slug' element={<AIPromotionsSingle/>}></Route>
                                    {/*<Route  path='/solar-payment-options/' element={<AIHowYouPay/>}></Route>*/}
                                    <Route  path='/faqs-about-solar-power/' element={<AIFaq/>}></Route>
                                    <Route  path='/let-there-be-light/' element={<AIThankYou/>}></Route>
                                    <Route  path='/let-there-be-light-commercial/' element={<AIThankYou/>}></Route>
                                    <Route  path='/let-there-be-light-residential/' element={<AIThankYou/>}></Route>
                                    <Route  path='/contact-us/' element={<AIContact/>}></Route>
                                    <Route  path='/legal/' element={<AILegal/>}></Route>
                                    <Route  path='/expand-as-you-go/' element={<ExpandAsYouGo/>}></Route>
                                    <Route  path='/search-results/:searchValue' element={<AISearchResults/>}></Route>
                                    <Route  path='*' element={<AI404/>}></Route>
                                    <Route  path='/start-saving' element={<StartSaving/>}></Route>
                                    <Route  path='/start-saving/details' element={<StartSavingForm/>}></Route>
                                    <Route  path='/start-saving/details-epct' element={<StartSavingForm/>}></Route>
                                    <Route  path='/start-saving/let-there-be-light-commercial' element={<StartSavingThankYou/>}></Route>
                                    <Route  path='/start-saving/let-there-be-light-residential' element={<StartSavingThankYou/>}></Route>
                                    <Route  path='/start-saving/let-there-be-light-maintenance-and-repairs' element={<StartSavingThankYou/>}></Route>
                                    <Route  path='/book-one-of-solar-solutions-experts' element={<BookConsultation/>}></Route>
                                    <Route  path='/backup-solutions' element={<BackupSolutions/>}></Route>
                                    <Route  path='/home-solar-battery-backup' element={<SolarBatterySolutions/>}></Route>
                                    {/* <Route  path='/hot-water-solar-solutions' element={<HotWaterSolarSolutions/>}></Route> */}
                                    <Route  path='/brands/alpha-ess' element={<BrandsAlpha/>}></Route>
                                    <Route  path='/brands/sunsynk' element={<BrandsSunsynk/>}></Route>
                                    <Route  path='/solar-system-for-house' element={<AIWhatWeDo/>}></Route>
                                    <Route  path='/articles' element={<Articles/>}></Route>
                                    <Route  path='/articles/:slug' element={<SingleArticle/>}></Route>
                                    <Route  path='/privacy-policy' element={<AIPrivacyPolicy/>}></Route>
                                    <Route  path='/terms-and-conditions' element={<AITermsAndConditions/>}></Route>
                                    <Route  path='/instant-quote-home-solar-power' element={<InstantQuote/>}></Route>
                                    <Route  path='/solar-calculator-south-africa' element={<SizingTool/>}></Route>
                                    <Route  path="/off-grid-solar-system" element={<OffGridSolarSystem/>}></Route>
                                    <Route  path='/go-solar' element={<GoSolar/>}></Route>
                                    <Route  path='/solar-system-with-battery' element={<SolarSystemWithBattery/>}></Route>
                                    <Route  path='/solar-backup' element={<SolarBackup/>}></Route>
                                    <Route  path='/solar-solutions-for-home-why-solar-energy-is-the-way-to-go' element={<SolarSolutionsForHomeWhyGoSolar/>}></Route>
                                    <Route  path='/solar-power-system-for-home' element={<SolarPowerSystemForHome/>}></Route>
                                    <Route  path='/solar-panel-battery-get-switched-on-with-alumo' element={<SolarPanelBatterGetSwitched/>}></Route>
                                    <Route  path='/solar-battery-backup-systems-beat-the-blackouts-with-alumo' element={<SolarBatteryBackupSystems/>}></Route>
                                    <Route  path='/rent-to-own-solar-system-everything-you-need-to-know' element={<RentToOwnSystem/>}></Route>
                                    <Route  path='/comprehensive-guide-to-solar' element={<SolarInstallation/>}></Route>
                                    <Route  path='/solar-panel-prices' element={<SolarPanelPrices/>}></Route>
                                    <Route  path='/quicklysign-thank-you' element={<QuicklySignThankYou/>}></Route>
                                    <Route  path='/careers' element={<Careers/>}></Route>
                                    <Route  path='/solar-rentals' element={<SolarRentals />}></Route>
                                    <Route  path='/solar-systems-south-africa' element={<SolarSystemsSouthAfrica />}></Route>
                                    <Route  path='/rental-solar-reduce-electricity-costs' element={<RentalSolar />}></Route>
                                    <Route  path='/maintenance-and-repairs' element={<MaintenanceAndRepairs/>}></Route>
                                    {/*    COMMERCIAL PAGES     */}
                                    <Route  path='/commercial' element={<Commercial />}></Route>
                                    {/*    RESIDENTIAL   */}
                                    <Route  path='/residential' element={<Residential />}></Route>
                                    <Route  path='/disclaimer' element={<Disclaimer />}></Route>
                                    {/*    SUBSCRIPTION PAGES   */}
                                    <Route  path='/subscription' element={<Subscription />}></Route>
                                    <Route  path='/login' element={<Login />}></Route>
                                    <Route  path='/feedback' element={<Feedback />}></Route>
                                    </Routes>
                              </div>
                          </HelmetProvider>
                    </div>
                  <Footer/>
              </AIRouter>
          </Suspense>
       );
}

export default RouteIndex;